.animate-slowdown {
  animation: slowDownAnimation 10s infinite;
}

@keyframes slowDownAnimation {
  0%,
  100% {
    opacity: 0.75;
  }

  50% {
    opacity: 0.75;
  }
}
